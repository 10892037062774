import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react';
import { RiNumber1,RiNumber2,RiNumber3 } from "react-icons/ri";



const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box bgColor={"white"} p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon color={"black"}  as={RiNumber1} w={10} h={10} />}
          title={'Eksik Konunuza yoğunlaşın'}
          text={
            'Şemsiyye, Usul-u Fıkıh ve Şerhi Akaid kitaplarından eksik olduğunu düşündüğünüz suallere odaklı bir çalışma gerçekleştirebilirsiniz.'
          }
        />
        <Feature
          icon={<Icon color={"black"} as={RiNumber2} w={10} h={10} />}
          title={'Kendinizi Genel Olarak Test Edin'}
          text={
            'Bütün sorulara hazır olduğunuzu düşünüyorsanız rastgele soru seçeneği ile üç kitaptan rastgele soruları cevaplandırın.'
          }
        />
        <Feature
          icon={<Icon color={"black"} as={RiNumber3} w={10} h={10} />}
          title={'Arkadaşlarınızla Çalışın'}
          text={
            'Talebe arkadaşlarınızla grup çalışması yapmak istiyorsanız rastgele veya konu odaklı soruları birbirinize sorabilirsiniz.'
          }
        />
      </SimpleGrid>
    </Box>
  );
}