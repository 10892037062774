import {
    Box,
    Container,
    Stack,
    Text,
    useColorModeValue
  } from '@chakra-ui/react';


  
  export default function SmallWithLogoLeft() {
    return (
      <Box
        bg={"blue.100"}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={10}
          justify={{ base: 'center', md: 'center' }}
          align={{ base: 'center', md: 'center' }}>

          <Text>Ulucami A Kültür Komisyonu 26. Devre</Text>
          {/* <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'#'}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'}>
              <FaInstagram />
            </SocialButton>
          </Stack> */}
        </Container>
      </Box>
    );
  }