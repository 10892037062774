import React, { useState } from 'react';
import { 
  Card,
  Heading,
  Button,
  Text,
  Divider,
  Tabs,
  TabList,
  Tab,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ChakraProvider,
  Container,
  Center,
  Grid,Box,
  GridItem
} from '@chakra-ui/react';
import "arabic-fonts/src/css/arabic-fonts.css";
import './App.css';
import NavBar from './templates/navbar';
import SmallWithLogoLeft from './templates/footer';
import WithLargeQuote from './templates/testimonial';
import WithSpeechBubbles from './templates/getting_started';
import CdTimerComp from './templates/cdTimerComp';


const categories = ['Usul-u Fıkıh', 'Şerh-i Akaid', 'Şemsiyye'];



const qaPairs = require('./templates/qaData');

const CryptoJS = require("crypto-js");

function getRandomIndex(max) {
  // Generate a random number between 0 and max - 1

  const randomNumber = CryptoJS.lib.WordArray.random(8).words[0];
  console.log(randomNumber)

  return  randomNumber% max;
}

function App() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectCategory = (category) => {
    setSelectedCategory(category);
    setCurrentQuestionIndex(0);
  };

  const nextQuestion = () => {
    setCurrentQuestionIndex(prevIndex => (prevIndex + 1) % qaPairs[selectedCategory].length);
  };

  const showAnswer = () => {
    onOpen();
  };

  const showRandomQuestion = () => {
    if (selectedCategory) {
      const maxIndex = qaPairs[selectedCategory].length;
      const randomIndex = getRandomIndex(maxIndex);
      setCurrentQuestionIndex(randomIndex);
    }
  };

  const showRandomQuestion_All = () => {
    const allQuestions = categories.flatMap(category => qaPairs[category]);
    const maxIndex = allQuestions.length;
    const randomIndex = getRandomIndex(maxIndex);
    const randomQuestion = allQuestions[randomIndex];
    const randomCategory = categories.find(category =>
      qaPairs[category].some(qa => qa.question === randomQuestion.question)
    );
    
    setSelectedCategory(randomCategory);
    setCurrentQuestionIndex(qaPairs[randomCategory].findIndex(qa => qa.question === randomQuestion.question));
  };
  const previousQuestion = () => {
    setCurrentQuestionIndex(prevIndex =>
      prevIndex === 0 ? qaPairs[selectedCategory].length - 1 : prevIndex - 1
    );
  };

  return (
    <ChakraProvider>
      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gap='1'
      >
        <GridItem  area={'header'}>
          <NavBar></NavBar>
        </GridItem>
      
        <GridItem pl='2' area={'main'}>
          <Center>
            <Container centerContent padding={"1em"} maxW={ {base: '2xs', md: '5xl'} } bg='gray.50' color='white'>
              <Stack mt='6' spacing='3'>
                <Card bgColor={"blue.50"} align='center'>
                  <Heading
                    fontWeight={600}
                    fontSize={{ base: 'xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'} mb={"8"} mt='8'>
                    <Text as={'span'} color={'blue.400'}>
                      Ulucami Tekamül
                    </Text>
                    {' '}Çalışma Sualleri
                  </Heading>
                </Card>
                <Card bgColor={"white"} paddingLeft={"3em"} paddingRight={"3em"}>
                <Stack align={"center"} spacing='3'>
                <CdTimerComp></CdTimerComp>
                
                </Stack>
                </Card>
                <Card bgColor={"white"}>
                  <Stack align={"center"} mt='6' spacing='3'>
                    
                  <Text align="center" fontSize={ {base: 'xs', md: '2xl'} }>(Daha iyi bir deneyim için otomatik Google Çeviriyi kapatınız.)</Text>
                    <Text align="center" fontSize={ {base: 'xl', md: '4xl'} }>Kitap Seçin</Text>
                    <Divider />
                    <Tabs align="center" variant='soft-rounded' colorScheme='linkedin'>
                      <TabList mb='2em'>
                        {categories.map((category) => (
                          <Tab key={category} onClick={() => selectCategory(category)}>
                            <Text fontSize={ {base: 'xs', md: 'xl'} }>{category}</Text>
                            </Tab>
                        ))}
                      </TabList>
                    </Tabs>
                  </Stack>
                  {selectedCategory && (
                    <Stack  align={"center"} mt='6' spacing='3'>
                      <Box maxW={ {base: '2xs', md: '4xl'}} >

                      <Text color='blue.600' fontSize={ {base: 'xl', md: '4xl'}}>
                          {selectedCategory+" "+(currentQuestionIndex + 1)}
                        </Text>
                        <Text color='blue.600' fontSize={ {base: 'xl', md: '4xl'}}>
                        <p dir="rtl" className="arabic">{qaPairs[selectedCategory][currentQuestionIndex].question}</p>
                          
                        </Text>
                      </Box>
                      {/* <Stack mb={"8"} mt='8' spacing='3'>
                        <Button onClick={showAnswer}>Cevabı Göster</Button>
                        <Button _hover bgColor={"blue.100"} onClick={nextQuestion}>Sonraki Soru</Button>
                        <Button _hover bgColor={"blue.100"} onClick={showRandomQuestion}>{selectedCategory} Rastgele Soru Göster</Button>
                        <Button _hover bgColor={"blue.100"} onClick={showRandomQuestion_All}>Tüm Konulardan Rastgele Soru Göster</Button>
                      </Stack> */}
                      <Stack mb={"8"} mt='8' maxW={ {base: '2xs', md: '4xl'} } spacing='3' direction="row" justifyContent="center">
  <Button
    onClick={previousQuestion}
    disabled={currentQuestionIndex === 0}
    _hover bgColor={"blue.100"}
    fontSize={ {base: '2xs', md: 'md'}}
  >
    Önceki Soru
  </Button>
  <Button fontSize={ {base: '2xs', md: 'md'}} onClick={showAnswer}>Cevabı Göster</Button>
  <Button fontSize={ {base: '2xs', md: 'md'}} _hover bgColor={"blue.100"} onClick={nextQuestion}>Sonraki Soru</Button>
</Stack>
<Divider></Divider>
<Stack mb={"8"} mt='8' spacing='3' direction="row" justifyContent="center">
  <Button
    _hover bgColor={"blue.100" }
    onClick={showRandomQuestion}
    fontSize={ {base: '2xs', md: 'md'}}
  >
    {selectedCategory+' '} 
    Rastgele Soru Göster
  </Button>
  <Button fontSize={ {base: '2xs', md: 'md'}} _hover bgColor={"blue.100"} onClick={showRandomQuestion_All}>Tüm Konulardan Rastgele Soru Göster</Button>
</Stack>
                      <Modal  isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent  maxW={ {base: '2xs', md: '4xl'} } >
                          <ModalHeader>Cevap</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                          {qaPairs[selectedCategory][currentQuestionIndex].answer.map((category) => (
                                                      <html dir="RTL">
                                                      <Text  fontSize={ {base: 'xl', md: '3xl'} }> 
                                                      <p className="arabic">{category}</p>
                                                      </Text>
                                                      </html>
                        ))}

                          </ModalBody>
                          <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                              Cevabı Kapat
                            </Button>
                           
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Stack>
                  )}

                </Card>
                <Card  bgColor={"blue.50"} align={"center"}>
                  <WithLargeQuote></WithLargeQuote>
                </Card>
                <Card bgColor={"blue.50"} align={"center"} mb={"8"} mt='8'>
                  <WithSpeechBubbles></WithSpeechBubbles>
                </Card>
              </Stack>
            </Container>
          </Center>
        </GridItem>
        <GridItem area={'footer'}>
          <Card  mt='8'>
            <SmallWithLogoLeft></SmallWithLogoLeft>
          </Card>
        </GridItem>
      </Grid>

    </ChakraProvider>
  );
}

export default App;
