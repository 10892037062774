import {  Box, Stack, Text} from '@chakra-ui/react';
import { useState, useEffect } from "react";
import styles from "./Slideshow.module.css";

const quotes = require("./word")


const WithLargeQuote = () => {
  const [index, setIndex] = useState(0);

useEffect(() => {
const intervalId = setInterval(() => {

    setIndex((prevIndex) => (prevIndex === quotes.length - 1 ? 0 : prevIndex + 1));

},20000);


return () => clearInterval(intervalId);
}, []);







  return (
   

         <Box bgColor={"white"} width={ {base: '2xs', md: '4xl'} } maxW={ {base: '2xs', md: '4xl'} } className={styles.slideshow}>
      <Box width={ {base: '2xs', md: '4xl'} } maxW={ {base: '2xs', md: '4xl'} } className={styles.slides}>
        <Box  width={ {base: '2xs', md: '4xl'} } maxW={ {base: '2xs', md: '4xl'} } className={`${styles.slide} ${styles.active}`} key={index}>

        <Stack 
      
      width={ {base: '2xs', md: '4xl'} } maxW={ {base: '2xs', md: '4xl'} }
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}>

      <Text 
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign={'center'}
        maxW={'3xl'}
        className={styles.quote}
        >
           {quotes[index].quote}
      </Text>
      <Box width={ {base: '2xs', md: '4xl'} } maxW={ {base: '2xs', md: '4xl'} } textAlign={'center'}>
        {/* <Avatar
          alt={'Ahmet Arif Denizolgun Bey Ağabeyimiz'}
          mb={2}
        /> */}

        <Text className={styles.author} fontWeight={600}>- {quotes[index].author}</Text>

      </Box>


      </Stack>
      </Box>
    </Box>
    </Box>




  );

}

export default WithLargeQuote;