const qaPairs = {
    "Usul-u Fıkıh": [
      { question: "اصول فقهك تعريفى، موضوعى، غايه سى، اوقوديغمز كتابك اسمى و مصنفنى يازيگز", answer: ["تعريفى : وَهُوَ عِلْمٌ يُعْرَفُ بِهِ اَحْوَالُ الْاَدِلَّةِ وَ الْاَحْكَامِ الشَّرْعِيَّتَيْنِ مِنْ حَيْثُ اَنَّ لَهَا دَخْلًا  فِى اِثْبَاتِ الثَّانِيَةِ بِالْاُولَى ","موضوعى : ادلهء شرعيه و احكام شرعيه در.","غايه سى : مَعْرِفَةُ الْاَحْكَامِ الرَّبَّانِيَّةِ بِحَسَبِ الطَّاقَةِ الْاِنْسَانِيَّةِ لِيُنَالَ بِالْجَرْيَانِ عَلَى مُوجَبِهَا لِلسَّعَادَةِ الدِّينِيَّةِ وَالدُّنْيَوِيَّةِ","كتابك اسمى : مرآة الاصول فى شرح مرقاة الوصول","كتابك مصنفى : مُلَّا خسرو حضرتلرى. "] },
      { question: "مرقات كتابنك تقسيماتنى ياپيكز. ", answer: ["بر مقدمه ، ايكى مقصد ، بر خاتمه دن متشكلدر.","مقدمه : علم اصول فقهك تعريفى، موضوعى و غايه سى ","ايكى مقصد : برنجى مقصد: ادلهء شرعيه ؛ ايكنجى مقصد: احكام شرعيه خاتمه : اجتهاد حقنده در."] },
      { question: "ادلهء اربعه نك ضبط وجهنى يازيكز", answer: ["دليل يا وحى اولور ويا غير وحي اولور وحى اولان يا مَتْلُوْ اولور، بو كتابدر. ويا غير مَتْلُوْ اولور، بوده سنتدر.   غير وحى اولان، عصرك بتون مجتهدلرينك قولى اولورسه بو اجماعدر، بر مجتهدك قولى اولورسه  بو قياسدر. "] }
      ,{ question: "ادلهء شرعيهء اصليه يى صايارق كتابك تعريفنى يازيكز.", answer: ["ادلهء شرعيه اصليه : كتاب، سنت، اجماع، قياس.","كتاب : هُوَ النَّظْمُ الْمُنْزَلُ عَلَى رَسُولِنَا مُحَمَّدٍ {صَلَّى اللهُ عَلَيْهِ وَ سَلَّمَ} اَلْمَنْقُولُ عَنْهُ تَوَاتُرًا "] }
      ,{ question: "قرآن كريمك حكملرى نه لردر؟    ", answer: ["١ - مُنْكِرِى كافر اولور","٢ - نمازده اوقونمسى جائز اولور","٣- آبدست سز و جنب اولارق طوقونمق جائز دگلدر","٤- قَطْعِى حكم افاده ايدر"] }
      ,    { question: "خاصك تعريف و حكمنى يازيكز.", answer: ["تعريفى :  لَفْظٌ وُضِعَ لِمَعْنًى وَاحِدٍ عَلَى الْاِنْفِرَادِ","حكمى :  خاص، خاص اولمسى اعتباريله مدلولنده قطعيت افاده ايدر"] }
      ,{ question: "امرى تعريف ايده رك امر ايله مرادك نه اولديغنى يازيكز.", answer: ["عريفى : لَفْظٌ طُلِبَ بِهِ الْفِعْلُ جَزْمًا بِوَضْعِهِ لَهُ اسْتِعْلَاءً ","امرك مرادى وجوبدر.{وجوب صيغه يه مخصوصدر ، صيغه وجوبه مخصوصدر}"] }
      ,{ question: "ظرف و معيارى تعريف ايدوپ، نماز ايجون نفس وجوب و وجوب اداء نه زمان تحقق ايدر؟ يازيكز", answer: ["ظرف : مَا يَفْضُلُ عَنِ الْمُؤَدَّى /   مِعْيَارْ : مَا لَا يَفْضُلُ عَنِ الْمُؤَدَّى ","نَفْسِ وُجُوبْ ؛ وقتك گيرمسيله ، وُجُوبُ اَدَاءْ ايسه خِطَابِ اِلَهِى نِكْ تَوَجُّهُى ايله تَحَقُّقْ ايدر"] }
      ,{ question: "ادايى تعريف ايدوب قسملريني برر مثال ايله يازيكز.", answer: ["اداء: تَسْلِيمُ عَيْنِ الْوَاجِبِ بِالْاَمْرِ","اداء اوج قسمدر:اداء كامل  { جماعتله نماز كبي } ","اداء قاصر  { منفردا نماز كبي } ","اداء شَبِيهٌ بِالْقَضَاءْ {لاحقك امام نمازدن فَارِغْ اولدقدن صوكره كى فعلى كبي }"] }
      ,{ question: "كافرلر نه ايله مأموردر ، نه ايله مأمور دكلدر؟", answer: ["كافرلر ايمان ، معاملات ، عقوبات ايله و عِبَادَاتِكْ وجوبنه اعتقاد ايله مأموردرلر.  سقوطه احتمالى اولان عبادتلر ايله امر اولونمازلر"] }
      ,{ question: "مطلق و مقيدى تعريف ايدوب حكملرينى يازيكز", answer: ["مطلق : هُوَ الشَّايِعُ فِى جِنْسِهِ بِلاَ شُمُولٍ وَ لاَ تَعْيِينٍ","مقيد :  هُوَ الْخَارِجُ عَنِ الشُّيُوعِ بِوَجْهٍ مَا","حكملرى : مطلق اطلاقى اوزره ، مقيد تقييدى اوزره جريان ايدر"] }
      ,{ question: "الفاظ عامى تعداد ايديكز", answer: ["الفاظ عام : جَمِعْ مُعَرَّفْ و جمع معرف معناسنه اولانلر ، مفرد معرف  و مفرد معرف معناسنه اولانلر، نَكْرَهءِ مَنْفِيَّه ، من ، ما ، الذي ، اين ، حيث ، متى ، كل ، كلما و جميع "] }
      , {question: "ظاهرك تعريفنى و حكمنى يازيكز", answer: ["تعريفى :  مَا عُرِفَ مُرَادُهُ بِسِمَاعِ صِيغَتِهِ","حكمى : تاويل ، تخصيص و نسخه احتمالى اولمقله برابر كنديسى ايله عملك واجب اولماسيدر"] }
      ,{ question: "محكمك تعريفنى و حكمنى يازيكز", answer: ["تعريفى :  مَا ازْدَادَ قُوَّةً عَلَي الْمُفَسَّرِ بِخُلُوِّهِ عَنِ احْتِمَالِ النَّسْخِ","حكمى : تاويل ، تخصيص و نسخه احتمالي اولمقسيزن كنديسي ايله عملك وموجبنه اعتقادك واجب اولماسيدر"] }
      ,{ question: "خفي نك تعريفنى و مثالنى يازيكز", answer: ["تعريفى :  مَا خَفِيَ مُرَادُهُ بِعَارِضٍ غَيْرِ الصِّيغَةِ","مثالى   : {اَلسَّارِقُ وَ السَّارِقَةُ فَاقْطَعُوا اَيْدِيَهُمَا} آيت كريمه سنده السارق لفظنك طَرَّارْ و نَبَّاشْ حقنده خفى  اولماسى كبى"] }
      ,{ question: "متشابه ك تعريفنى و حكمنى يازيكز ", answer: ["تعريفى : مَا انْقَطَعَ رَجَاءُ مَعْرِفَةِ مُرَادِهِ","حكمى : مراد اولونان معنانك حَقِّيَّتِنَه اعتقاد ايدوب ، هر نه قدر متأخرون جواز ويرسه ده تأويلدن قاجينمقدر"] }
      ,{ question: "حقيقت و مجازك تعريفلرينى يازيكز ", answer: ["حقيقت :  مَا اسْتُعْمِلَ فِيمَا وُضِعَ لَهُ . ","مجاز   :   مَا اسْتُعْمِلَ فِي غَيْرِ مَا وُضِعَ لَهُ لِعَلَاقَةٍ بَيْنَهُمَا "] }
      ,{ question: " دال بالعباره و دال بالاشاره نك تعريفلرى و حكملرى نه لردر", answer: ["بالعباره  : مَا دَلَّ بِاِحْدَى الدَّلَالَاتِ الثَّلَثِ عَلَى مَا سِيقَ لَهُ ","دال بالاشاره : مَا دَلَّ بِاِحْدَى الدَّلَالَاتِ الثَّلَثَ عَلَى مَا لَيْسَ لَهُ السِّيَاقُ","حكملرى: قطعيت افاده ايدرلر"] }
      ,{ question: "استدلالات فاسده لر نه لردر؟", answer: ["١- مفهوم مخالفت ،","٢- نظمده كى ياقينلغك حكمده مُسَاوَاتِى ايجاب ايتمسى ","٣ - عَامِّى سببنه تخصيص ايتمك ","٤- عامى متكلمك غرضنه تخصيص ايتمك ","٥ - مطلقى مُقَيَّدْ اوزرينه مطلق اولارق حمل ايتمك "] }
      ,{ question: "بيانات خمسي صاييكز؟ ", answer: ["بيان تقرير ، بيان تفسير ، بيان تغيير ، بيان ضرورت ، بيان تبديل"] }
      ,{ question: "نسخك تعريفنى و جريان ايتديكى يرلرى يازيكز", answer: ["تعريفى : وَهُوَ اَنْ يَدُلَّ عَلىَ خلِاَفِ حُكْمٍ شَرْعِىٍّ دَلِيلٌ شَرْعِىٌّ مُتَرَاخٍ","دورت يرده جريان ايدر :    كتاب ايله كتاب ، كتاب ايله سنت سنت ايله سنت ،  سنت ايله كتاب "] }
      ,{ question: "كتابدن منسوخ اولانلر نه لردر؟", answer: ["سادجه تلاوت   /  سادجه حكم  /  حكمله برابر تلاوت  /  ويا حكمك وصفى در"] }
      ,{ question: "سنتك تعريفنى و قسملرينى يازيكز", answer: ["تعريفى :  مَا صَدَرَ عَنِ النَّبِىِّ {صَلَى اللهُ عَلَيْهِ وَ سَلَّمَ} مِنْ قَوْلٍ اَوْ فِعْلٍ اَوْ تَقْرِيرٍ."," سنت اوج قسمدر  : سنت قوليه - سنت فعليه  –     سنت تقريريه "] }
      ,{ question: "شروط راوي يى تعداد ايدوب ضبطك جمع ايتديكى معنالرى يازيكز", answer: ["دورت در : عَقْلِ كَامِلْ ، اسلام ، ضبط ، عدالت","ضبط دورت معنايى ايجينه آلير : حَقُّ السِّمَاعْ ، فَهْمُ الْمَعْنَى ، حِفْظُ اللَّفِظْ، مُرَاقَبَهْ "] }
      ,{ question: "خبر واحدك عمل ايجاب ايتديكى نه ايله ثابتدر؟", answer: ["كتاب { فَلَوْ لَا نَفَرَ مِنْ كُلِّ فِرْقَةٍ مِنْهُمْ طَائِفَةٌ لِيَتَفَقَّهُوا فِى الدِّينِ } / سنت، اجماع و قياس ايله ثابتدر"] }
      ,{ question: "مرسل حديثك قسملرى نه لردر؟", answer: ["١- صحابه نك مرسلى  ","٢- قَرْنِ ثَانِى و ثَالِثِكْ مرسلى","٣- هر عصرده كى عادل كيمسه لرك مرسلى ","٤- مِنْ وَجْهٍ مرسل مِنْ وَجْهٍ مسند"] }
      ,{ question: " نفس خبرك قسملرينى مثاللريله برابر يازيكز.  ", answer: ["دورت قسمدر :","•	صدقى معلوم اولان { رسوللرك خبرى كبى}","•	كذبى معلوم اولان  { فرعونك رُبُوبِيَّتْ دعواسى كبى}","•	بِلاَ تَرْجِيحْ صدقه و كذبه احتمالى اولان { فاسقك خبرى كبى}","•	صدقى كذبى اوزرينه ترجيح ايديلن { شرطلرينى جَامِعْ عادل  كيمسه نك خبرى كبى}"] }
      ,{ question: "سنت فعليه يى ايضاح ايديكز", answer: ["{رسول الله افنديمزك كنديسنه مخصوص فعللرى ، مُجْمَلِى بياني ، طبيعى فعللرى و زَلَّه نِكْ خارجنده قلان} قصدى فعللرينك صفتى معلوم اولورسه او صفتلرده امت، رسول الله كبيدر. صفتى معلوم اولمازسه او فعل رسول الله افنديمز ايجون مباح ، بزم ده اونه تابع اولمامز جائزدر."] }
      ,{ question: "اجماعك تعريفنى و حكمنى يازيكز.  ", answer: ["تعريفى : اِتِّفَاقُ الْمُجْتَهِدِينَ مِنْ اُمَّةِ مُحَمَّدٍ { صَلَّى اللهُ عَلَيْهِ وَ سَلَّمَ } فِى عَصْرٍ  عَلَى حُكْمٍ شَرْعِىٍّ","حكمى :  اجماع ، اجماع اولمسى حيثيتى ايله قطعيت افاده ايدر ،   حُجِّيَّتِنِى إنكار ايدن كافر اولور. "] }
      ,{ question: "قياسك تعريفنى و حجيتنك نه ايله ثابت اولديغنى بيان ايديكز.", answer: ["تعريفى : هُوَ اِبَانَةُ مِثْلِ حُكْمِ اَحَدِ الْمَذْكُورَيْنِ بِمِثْلِ عِلَّتِهِ فِى الْآخَرِ باِلرَّأْىِ","كتاب ايله {فَاعْتَبِرُوا يَا اُولِى الْاَبْصَارِ} ","سنت ايله {معاذ بن جبل حديثى كبى} ","و اجماع ايله ثابتدر"] }
      ,{ question: "مقصد ثانى نه حقنده در ؟ ركنلرينى يازيكز", answer: ["احكام شرعيه و اوكا تعلق ايدن شيلر حقنده در.","٤ ركنى واردر : حكم ، حاكم ، محكوم به ، محكوم عليه"] }
      ,{ question: "عزيمتك تعريفى و قسملرى نه لردر ؟", answer: ["عزيمت : مَا شُرِعَ ابْتِدَاءً غَيْرَ مَبْنِىٍّ عَلَى اَعْذَارِ الْعِبَادِ","قسملرى:   فرض ، واجب ، سنت ، نافله ، حرام ، مكروه ، مباح"] }
      ,{ question: " فرض ، سنت ، مكروه  و حرامك قسملرينى بيان ايديكز؟ ", answer: ["فرض : فرض عين ـ فرض كفايه 	سنت : سنت هُدَي ـ سنت زَوَائِدْ","حرام :  حرام لعينه ـ حرام لغيره   	مكروه : تحريمى ـ تنزيهى"] }
      ,{ question: "حكم وضعى نك قسملرينى يازيكز", answer: ["قسملرى : ركن ، علت ، سبب ، شرط ، علامت "] }
      ,{ question: "ركنى تعريف ايدوب قسملرينى مثاللريله برابر يازيكز", answer: ["تعريفى : مَا يَتَقَوَّمُ بِهِ الشَّئْ ُ","قسملرى : - ركنى اصلى: {ايمان ايجون تصديق كبى} ","- ركن زَائِدْ :  {ايمان ايجون اقرار كبى}"] }
      ,{ question: "محكوم به قاج قسمدر؟  ", answer: ["دورت قسمدر : ","١) حقوق الله خالصه ","٢) حقوق العباد خالصه","٣) ايكيسى اجتماع ايدوب حقوق الله غالب اولان","   ٤) ايكيسى اجتماع ايدوب حقوق العباد غالب اولان"] }
      ,{ question: "محكوم عليه كيمدر ، تكليف نه يه باغلى در؟  ", answer: ["محكوم عليه مكلفدر ، تكليف اهليته ، اهليت دخى عَقِلْ بِالْمَلَكَه يَه باغلي در."] }
      ,{ question: "اهليت قاج قسمدر؟ اهليت وجوب نه ايله ثابت اولور؟", answer: ["ايكى قسمدر: اهليت وجوب / اهليت اداء ، اهليت وجوب ذمتله ثابتدر.","{ذِمَّتْ؛ انسانك لهنه و عليهنه اولان شيلره كنديسيله اَهِلْ اولديغى بر وصفدر}"] }
      ,{ question: "عوارض سماويه و مكتسبه نه لردر؟", answer: ["عوارض سماويه : جُنُونْ ، صِغَرْ ، عَتَهْ ، نِسْيَانْ ، نَوْمْ ، اِغْمَا ، رِقْ  ، حيض ، نفاس ،  مرض ، موت","عوارض مكتسبه : جَهِلْ ، سَكِرْ ، هَزِلْ ، سَفَهْ ، سَفَرْ ، خطا ، اكراه"] }
      ,{ question: " تركهء ميته تعلق ايدن حقلر نه لردر؟", answer: ["تجهيزُ و تكفين {من كل المال}    /    قضاءِ ديون {من كل المال} ","تنفيذِ وصايا {من ثلث المال}  / 	قسمت بين الورثه {من بقية المال} "] }
    ],
    'Şerh-i Akaid': [
      { question: "علم كلامك تعريفنى، موضوعنى و غايه سنى يازيكز؟ ", answer: ["تعريفى: عِلْمٌ يُبْحَثُ فِيهِ عَنْ ذَاتِ اللهِ تَعَالىَ وَ صِفَاتِهِ وَ اَحْوَالِ الْمُمْكِنَاتِ مِنَ الْمَبْدَأِ وَ الْمَعَادِ عَلَى قَانُونِ الْإِسْلَامِ","موضوعى: ذات الله ، صفات الله ، افعال الله ، مبدأ و معاد اعتباريله ممكناتك احواليدر","غايه سى: اَلتَّرَقِّى مِنْ خَضِيضِ التَّقْلِيدِ اِلَى ذِرْوَةِ الْيَقِينِ وَ اِرْشَادُ الْمُسْتَرْشِدِينَ وَ اِلْزَامُ الْمُعَانِدِينَ وَ حِفْظُ عَقَائِدِ الْمُسْلِمِينَ عَنْ شُبَهِ الْمُبْطِلِينَ. وَ غَايَةُ غَايَتِهِ: اَلْفَوْزُ بِسَعَادَةِ الدَّارَيْنِ"] },
      { question: "علم كلامك بحثلرينى و اونى اوكرنمه نك حكمنى يازيكز؟", answer: ["بحثلرى: الهيات، نبويات، سمعيات.","حكمى: اعتقادى مسئله لرى اجمالاً اوكرنمك فرض عين، تفصيلاً اوكرنمك فرض كفايه در."] },
      { question: "علم كلامك واضعنى، متن عقائدك  مصنفنى و شارحنى  يازيكز؟", answer: ["واضعى: ايلك واضعى امام اعظم حضرتلرى ، اوندن صوكره امام اشعرى وامام ماتريدى حضرتلريدر.","مصنفى: عمر النسفى حضرتلرى.","شارحى:سعدالدين مسعود بن عمر التفتازانى حضرتلريدر."] },
      { question: "صحابهء كرام و تابعين دورنده نيچون عمل واعتقادله علاقه لى كتابلر يازيلمامشدر؟", answer: ["صحابهء كرامك إعتقادى، ﭘيغمبر افنديمزك صحبتى سببى ايله تميز اولديغى ايچون،","تابعينك إعتقادى ده، زمانلرينك افنديمزك زماننه يقين اولمسى سببى ايله تميز اولديغى ايچون، ","واقعه و اختلافلر آز اولديغى ايچون،","كنديلرينه اعتماد ايديلن كيمسه لره مراجعت امكانى اولديغى ايچون يازيلمامشدر."] },
      { question: "فرق اسلاميه نك باشليجه لرى نه لردر؟", answer: ["باشليجه ۲ قسمه آيريلير. ۱- فِرْقَهءِ نَاجِيَه: اهل سنت و الجماعة  (ماتريديه و أشعريه) ","۲- فِرَقِ ضَالَّهْ: مُعْتَزِلَه  ، شِيعَه  ، خَوَارِجْ ،  مُرْجِئَه ،  نَجَّارِيَه ، جَبْرِيَّه  ، مُشَبِّهَه."] },
      { question: "مام ابو الحسن الأشعرى  و امام ماتريدى  كيملردر؟", answer: ["امام اشعرى: بغدادده ياشايوب اهل سنت عقيده سنى امام شافعى حضرتلرينك كوروشلرى اوزره احيا ايدن ذاتدر.","امام ماتريدى: مَاوَرَاءُ النَّهِرْدَه ياشايوب اهل سنت عقيده سنى امام اعظم حضرتلرينك كوروشلرى اوزره احيا ايدن ذاتدر."] },
      { question: "بو علمك موضوعى ذات الهى و صفات الهى اولديغى حالده مصنف حضرتلرى نيچون حقائق اشياء ايله باشلادى؟", answer: ["علم كلامك مَبْنَاسِى، مُحْدَثَاتِكْ وارلغندن حضرت اللهك وارلغنه، برلكنه، صفتلرينه، فعللرينه، بورادن ده سَائِرْ سَمْعِيَّاتَه استدلال ايتمك اولديغى  ايچون  باشلادى."] },
      { question: "علمى تعريف ايدوب اسباب علمى تعداد ايديكز؟", answer: ["علم: صِفَةٌ يَتَجَلَّى بِهَا الْمَذْكُورُ لِمَنْ قَامَتْ هِىَ بِهِ. ","اسباب علم: حواس سليمه، خبر صادق، عقل."] },
      { question: "خبر متواترك  تعريفنى و حكمنى بر مثال ايله يازيكز؟", answer: ["تعريفى: وَ هُوَ الْخَبَرُ الثَّابِتُ عَلَى اَلْسِنَةِ قَوْمٍ لَا يُتَصَوَّرُ تَوَاطُئُهُمْ عَلَى الْكِذْبِ.","حكمى: بالضروره ، علم ضرورى يى ايجاب ايدر","مثال: كجمش زمانده ياشامش پادشاهلرى و اوزاق بلدهلرى بيلمك كبى."] },
      { question: "خبر رسول هانكى علمى ايجاب ايدر؟ رسول و معجزه يى تعريف ايديكز؟", answer: ["خبر رسول علم استدلالى يى ايجاب ايدر. ","رسول: اِنْسَانٌ بَعَثَهُ اللهُ تَعَالَى اِلَى الْخَلْقِ لِتَبْلِيغِ الْأَحْكَامِ. ","معجزه: اَمْرٌ خَارِقٌ لِلْعَادَةِ قُصِدَ بِهِ اِظْهَارُ صِدْقِ مَنْ اِدَّعَى اَنَّهُ رَسُولٌ مِنَ اللهِ تَعَالَى."] },
      { question: "خارق العاده أمرلر قاچ قسمدر و نه لردر؟  كرامتى تعريف ايديكز؟", answer: ["آلتى قسمدر: ارهاص، معجزه، كرامت، مَعُونَتْ، استدراج و اهانت.","كرامت: ظُهُورُ اَمْرٍ خَارِقٍ لِلْعَادَةِ مِنْ قِبَلِ الْوَلِىِّ غَيْرَ مُقَارِنٍ لِدَعْوَى النُّبُوَّةِ."] },
      { question: "الهام نه در؟ اسباب علمدنميدر؟ إيضاح ايديكز ؟", answer: ["الهام: اِلْقَاءُ مَعْنًى فِى الْقَلْبِ بِطَرِيقِ الْفَيْضِ. اسباب علمدن دكلدر.","چونكه الهام كنديسيله عَامَّهءِ خَلْقْ ايچون علم حاصل اولان و غيرى الزام ايتمه يه صالح اولان بر سبب دكلدر."] },
      { question: "عالم نه در، نيچون محدثدر ؟", answer: ["عالم: مَا سِوَى اللهِ تَعَالَى مِنَ الْمَوْجُودَاتِ مِمَّا يُعْلَمُ بِهِ الصَّانِعُ. ","عالم مُحْدَثْدِرْ. چونكه عالم، محدث اولان اعيان و اعراضدن مركبدر."] },
      { question: "أعيان و أعراضى تعريف ايدوب أعيانك قسملرينى إيضاح ايديكز؟", answer: ["اعيان : مَا لَهُ قِيَامٌ بِذَاتِهِ               اعراض:مَا لَا يَقُومُ بِذَاتِهِ","اعيان؛ايكى ويا دها چوق جزدن مركب اولور كه بو جسم كبيدر؛ ويا مركب اولماز، جوهر كبى."] },
      { question: "محدِث عالم اولان حضرت الله نيچون واجب الوجوددر؟", answer: ["اكر جائز الوجود اولسه ايدی عالم جمله سندن اولوردى كه او زمان عالمك مُحْدِثِی اولمايه صالح اولمازدى."] },
      { question: "صفات تنزيهيه دن يدی(۷) دانه سنى يازيكز؟", answer: ["جناب حق عَرَضْ،جِسِمْ،جَوْهَرْ، مُصَوَّرْ، مَحْدُودْ، مُتَرَكِّبْ، مُتَبَعِّضْ، مُتَجَزِّى وَ مُتَنَاهِى دكلدر. مَائِيَّتْ وَ كَيْفِيَّتْ ايله وصفلانماز. بر مكانله مكانلاشماز. جناب حق اوزرينه زمان جريان ايتمز. جناب حقه هيچ بر شئ بكزه مز.جناب حقك علمندن وقدرتندن هيچ بر شئ چيقماز."] },
      { question: "عقائد اسلاميه نك دليللرى نه لردر؟", answer: ["اَدِلَّهِ سَمْعِيَّه ( كتاب و سنت ) و قَوَاعِدِ مَنْطِقِيَّه ( عقلى دليللر)"] },
      { question: "جناب حق نصل بر كلامله متكلمدر؟  كلام صفتنك ثابت اولديغنه دائر دليللريمز نه لردر؟", answer: ["جناب حق كندى ذاتيله قائم، ازلى، سس و حرف  جنسندن اولمايان، سُكُوتْ وَ آفَتَه مُنَافِى بر كلامله متكلمدر. ","دليللريمز: ۱- اجماع امت،"," ۲-انبياء عليهم الصلاة والسلامدن كلن الله تعالى متكلمدر شكلنده كى متواتر نقللر"] },
      { question: "قرآنك مخلوق اولوب اولمديغى خصوصنده اهل سنتك كوروشنى يازيكز؟", answer: ["كلام الله اولان قرأن ايكى معناده مشتركدر.","١-كلام نفسى: جناب حقك ذاتيله قائم اولوب ازليدر، مخلوق دكلدر،","٢- كلام لفظى: حرف و سسلردن مُؤَلَّفْ اولان نظمدر و مخلوقدر. "] },
      { question: "رؤية اللهك آخرتده واجب اولديغنه دائر نقلى دليللر نه لردر؟ ايضاح ايديكز؟", answer: ["كتاب، سنت و إجماع واردر. بونلر: ","١-كتاب: وُجُوهٌ يَوْمَئِذٍ نَاضِرَةٌ اِلَى رَبِّهَا نَاظِرَةٌ. ","٢- سنت: إِنَّكُمْ سَتَرَوْنَ رَبَّكُمْ كَمَا تَرَوْنَ اْلقَمَرَ لَيْلَةَ اْلبَدْرِ.","٣-  اجماع: آخرتده رؤيتك وقوعنه دائر اجماع امت واردر."] },
      { question: "افعال عباد حقنده اهل سنت، معتزله و جبريه نك كوروشلرى نه لردر؟ ", answer: ["اهل سنت: كفر -  ايمان، طاعت - عصيان كبى قولك فعللرينك خالقى جناب حقدر. قول ايسه كَاسِبْدِرْ.","معتزله: قول فعلنك خالقيدر. ","جبريه: قول جَمَادَاتْ كبيدر. فعللرنده هيچ بر تأثيرى يوقدر."] },
      { question: "قدر و قضا يى تعريف ايده رك علم مى معلومه يوقسه معلوم مى علمه تابعدر؟ يازيكز؟ ", answer: ["قدر: ازلدن ابده قدر جريان ايده جك حادثه لر حقنده جناب حقك علمنك اقتضاسنجه چيزديكى الهى پروغرامدر.","قضا: جناب حقك ازلده تقدير بيورديغى شيلرى زمانى كلنجه علم و اراده سنه موافق اولارق خلق ايتمه سيدر. *** علم معلومه تابعدر."] },
      { question: "جناب حقك اراده سى و رضاسى قوللرك فعللرندن هانكيلرينه تعلق ايدر؟ ", answer: ["اراده سى هم حسن هم ده قبيح اولانلرينه تعلق ايدر. رضاسى ايسه يالكزجه حسن اولانلرينه تعلق ايدر.(يعنى جناب حق قوللرك كوزل و چيركين بوتون فعللرينی ديلر وياراتير لكن ساده جه كوزل فعللرندن راضى اولور.)"] },
      { question: "استطاعتك قسملرينى و بو قسملرك مع الفعل مى، قبل الفعل مى اولديغنى يازيكز؟", answer: ["۱- فعلك كنديسيله ميدانه كلديكى قُدْرَتِ حَقِيقِيَّه معناسنده كى استطاعت،بو مع الفعلدر.","۲- سَلَامَةُ الْأَسْبَابْ وَ الْآلَاتِ مَعْنَاسِنْدَه كى استطاعت،  بو ايسه قبل الفعلدر."] },
      { question: "اجلى تعريف ايدوب، مقتول اجلى ايله مى اولمشدر؟ بر آيت كريمه ايله بيان ايديكز؟", answer: ["اجل: انسانك مقدر اولان عمرينك نهايتنه دينير. مقتول اجلى ايله اولمشدر. چونكه آيت كريمه ده فاِذَا جَاءَ اَجَلُهُمْ لَا يَسْتَأْخِرُونَ سَاعَةً وَ لَايَسْتَقْدِمُونَ بويورلمشدر."] },
      { question: "اهل سنت و معتزله يه كوره رزق نه در؟ حرام رزقميدر؟", answer: ["اهل سنته كوره رزق: اِسْمٌ لِمَا يَسُوقُهُ اللهُ تَعَالَى اِلَى اْلحَيَوَانِ فَيَأْكُلُهُ. حرام رزقدر.","معتزله يه كوره رزق: مالكك يديكى ملكدر ويا كنديسيله منفعتلنمك شرعا منع ايديلمهين شيدر. حرام رزق دكلدر."] },
      { question: "وَ اللهُ تَعَالَى يُضِلُّ مَنْ يَشَاءَ وَ يَهْدِى مَنْ يَشَاءَ افاده سندن مقصد نه در؟ ", answer: ["بو افاده دن مقصد شودر: جناب حق، هدايتى ديله ينلر ايچون هدايتى ديلر و ياراتير، ضلالتى ديله ينلر ايچون ده ضلالتى ديلر و ياراتير"] },
      { question: "اَصْلَحْ عَلَى اللهْ واجبميدر؟ قيصاجه ايضاح ايديكز؟", answer: ["واجب دكلدر. چونكه واجب اولسه ايدى جناب حق دنياده فَقِيرْ، آخرتده مُعَذَّبْ اولان كافرلرى ياراتمازدى. ينه حضرت اللهك هدايت و نعمتلرينه قارشى شكر لازم كلمزدى. زيرا جناب حق وظيفه سنى ياپمش اولوردى."] },
      { question: "قبر عذابى كيملر ايچوندر؟ قبر عذابنك ثبوتنه دائر بر آيت كريمه وبر حديث شريف يازيكز؟", answer: ["كافرلر و بعض عاصى مؤمنلر ايچوندر","آيت كريمه: اَلنَّارُ يُعْرَضُونَ عَلَيْهَا غُدُوًّا وَ عَشِيًّا وَ يَوْمَ تَقُومُ السَّاعَةُ أَدْخِلُوا آلَ فِرْعَوْنَ اَشَّدَ اْلعَذَابِ ","حديث شريف: اِسْتَنْزِهُوا عَنِ اْلبَوْلِ فَإِنَّ عَامَّةَ عَذَابِ اْلقَبْرِ مِنْهُ."] },
      { question: "منكر- نكير و كراما - كاتبين ملكلرى حقنده قيصه جه معلومات ويريكز؟", answer: ["منكر - نكير: ايكى ملكدر كه قبره داخل اولورلر و قوله ربندن، دينندن و نبيسندن سؤال ايدرلر. ","- كاتبين ايسه؛ انسانلرك ايى و كوتو فعللرينى قيد ايدن ملكلرد"] },
      { question: "بعث نه در؟ ثبوتنه دائر آيت كريمه يازيكز؟ ", answer: ["بعث: حضرت اللهك، مَوْتَى نِكْ آصلى جزلرينى جَمْعْ ايدوب روحلرينى اِعَادَه ايده رك قبرلرندن ديريلتمسيدر. ","آيت كريمه: ثُمَّ إِنَّكُمْ يَوْمَ الْقِيَامَةِ تُبْعَثُونَ. "] },
      { question: "تناسخ حقنده معلومات ويريكز؟ أهل سنته كوره حكمى نه در؟", answer: ["روحك انساندن انسانه، انساندن حيوانه، حيواندن انسانه كچمه سى شكلنده كى اينانيشدر. اهل سنته كوره باطلدر."] },
      { question: "وزن حقنده كى دليلمزى معتزله نك انكار سببى و جوابمزى يازيكز؟", answer: ["دليلمز: وَ الْوَزْنُ يَوْمَئِذٍ نِ الْحَقُّ  آيت كريمه سيدر. ","معتزله : عمللر حضرت الله ايچون معلومدر. او حالده طارتيلمه سى عبثدر.","جوابمز: حضرت اللهك بوتون فعللرينك حكمتلرى واردر. وزن حقنده كیحكمتلره مُطَّلِعْ اولامامامز وزنك عَبَثْ اولماسنى ايجاب ايتمز"] },
      { question: "كتابك (عمل دفترينك) حق اولديغنه دائر دليل نه در؟ ويريليش كيفيتى نه در؟", answer: ["آيت كريمه: فَأَمَّا مَنْ أُوتِىَ كِتَابَهُ بِيَمِينِهِ فَسَوْفَ يُحَاسَبُ حِسَابًا يَسيِرًا  مؤمنلره صاغ طرفندن، كافرلره صول و آرقه طرفلرندن ويريلير."] },
      { question: " حوضك حق اولديغنه دائر بر آيت كريمه و بر حديث شريف يازيكز؟", answer: ["آيت كريمه: إِنَّا اَعْطَيْنَاكَ الْكَوْثَرَ. ","حديث شريف: حَوْضِى مَسِيرَةُ شَهْرٍ، وَ زَوَايَاهُ سَوَاءٌ، وَ مَاؤُهُ اَبْيَضُ مِنَ الَّلبَنِ، وَ رِيحُهُ اَطْيَبُ مِنَ الْمِسْكِ، وَ كِيزَانُهُ اَكْثَرُ مِنْ نُجُومِ السَّمَاءِ، مَنْ شَرِبَ مِنْهُ فَلَا يَظْمَأُ اَبَدًا"] },
      { question: "صراط نه در؟ اكثر معتزله نك انكار سببنى و جوابمزى يازيكز؟", answer: ["صراط: جهنم اوزرينه اوزاتيلمش، قيلدن اينجه، قيليچدن كسكين، اهل جنتك كچه جكى، اهل جهنمك آياغنك قاياجغى بر كوپرودر","معتزله نك انكار سببلرى: بويله بر كوپريدن كچمك ممكن دكلدر، ممكن اولسه بيله مؤمنلره اذيتدر. ","جوابمز: جناب حق، مؤمنلرى صراطدن قولايجه كچيرميه قادردر. حتى كيمى شيمشك كبى كيمى ده روزكار كبى كچر."] },
      { question: "جنت و جهنم شو آن موجود ميدر؟ دليللرى نه در؟", answer: ["موجوددر. دليللر: آدم عليه السلام ايله حوا والده مزك قصه سى و شو آيت كريمه لردر: اُعِدَّتْ لِلْمُتَّقيِنَ، و  اُعِدَّتْ لِلْكَافِريِنَ"] },
      { question: "كناه كبائرى تعداد ايديكز؟", answer: ["۱- الله تعالى يه شرك قوشمق			٧- يتيم مالى يمك","۲- حقسز يره آدم اولدورمك	       ٨- مسلمان آنا بابايه عاصى اولمق","۳- مُحْصَنَه يه زنا افتراسنده بولونمق	         ٩- حرم شريفده كناه ايشله مك","٤- زنا ايتمك					۱٠- فائز ييمك","٥- حربدن قاچمق		        	۱۱ - خرسزلق ياپمق","٦- سحر ياپمق و ياپديرمق			۱۲- شراب ايچمك."] },
      { question: "مُرْتَكِبِ كَبِيرَه حقنده اهل سنت ، خوارج و معتزله نك كوروشلرينى يازيكز؟", answer: ["اهل سنت: مرتكب كبيره مؤمندر، چونكه تصديق باقيدر. ","خوارج: مرتكب كبيره كافردر. ","معتزله: مرتكب كبيره فاسقدر، مؤمن ده دكلدر، كافر ده دكلدر."] },
      { question: "شفاعت حقنده كى عقلى و نقلى دليللر نه لردر؟ ايضاح ايديكز؟", answer: ["عقلى دليل: عفو و مغفرت، شفاعت اولمادن جائز اولديغنه كوره شفاعت ايله اَوْلَوِيَتْلَه جائزدر","نقلى دليللر: كتاب، سنت و إجماع  در.","كتاب: فَمَا تَنْفَعُهُمْ (كافرلره) شَفَاعَةُ الشَّافِعِينَ.  ( بو آيت كريمه نك اسلوبى شفاعتك ثبوتنه دلالت ايدر.) ","سنت: شَفَاعَتِى لِأَهْلِ الْكَبَائِرِ مِنْ اُمَّتِى. ( بو حديث شريف مشهور اولمقله برابر، شفاعت حقنده كى حديثلرك معناسى متواتردر.)","اجماع: شفاعتك ثبوتنه دائر إجماع أمت واردر."] },
      { question: "ايمان لغتده و اصطلاحده نه ديمكدر؟", answer: ["ايمان لغتده: بر حكمه كوكلدن بويون اكمك و اونك طوغريلغنى قبول ايتمكدر. ","اصطلاحده ايسه: ﭘيغمبر افنديمزك (صلى الله عليه و سلم) حضرت الله طرفندن كتيرديكى خصوصلرك تمامنى تصديق و اقرار ايتمكدر."] },
      { question: "أنا مؤمن ان شاء الله ديمك مناسبميدر؟ ايضاح ايديكز؟", answer: ["مناسب دكلدر. چونكه اكر بو سوز شك ايچون اولورسه شبهه سز كفردر. شو قدر وار كه، ادبلى حركت ايتمك، ايشلرى جناب حقك مَشِيئَتِنَه حواله ايتمك، عاقبتندن شبهه ايتمك كبى سببلرله سويله نه بيليرسه ده أولى اولان تركدر."] },
      { question: "سعادت – شقاوت، إسعاد – إشقا  حقنده معلومات ويريكز؟ دكيشيكلك هانكيسنده در؟", answer: ["سعادت و شقاوت قولك صفتيدر. إسعاد و إشقا ايسه جناب حقك صفتيدر. دكيشيكلك قولك صفتى اولان سعادت و شقاوتده در. ( يعنى سعيد ايماندن صوكره مرتد اولمقله شقى اولابيلير. شقى ده كفردن صوكره ايمان ايتمكله سعيد اولابيلير.)"] },
      { question: "سالت نه ديمكدر؟ جناب حقك رسوللر كوندرمسنده كى حكمتلر نه لردر؟", answer: ["رسالت:حضرت اللهك بر قولنك كنديسى ايله مخلوقلرندن عقل صاحبلرى آراسنده كى سَفِيرْلِكِيدِرْ.","حكمتلرى: ","١ - اهل ايمان و اهل طاعتى جنت و ثوابله تبشير،","٢ - اهل كفر و اهل عصيانى جهنم و عذاب ايله انذار،","٣ - انسانلرك محتاج اولديغى دينى و دنيوى شيلرى بيان ايتمك."] },
      { question: "افنديمزك صوك پيغمبر اولديغى قطعى ايكن اوندن صوكره عيسى عليه السلامك نزولى ناصل ايضاح ايديله بيلير؟", answer: ["عيسى عليه السلام يكى بر شريعتله دكل، پيغمبر افنديمزك شريعتنه تابع اولارق كله جكدر."] },
      { question: "پيغمبرلرك عددى خصوصنده معلومات ويريكز؟", answer: ["بر روايته كوره يوز يكرمى دورت بيك، ديكر بر روايته كوره ايكى يوز يكرمى دورت بيك پيغمبر كوندريلمشدر. آنجاق أولى اولان بر عدده تخصيص ايتمه مكدر. ( نته كيم آيت كريمه شويله در:منهم من قصصنا عليك و منهم من لم نقصص عليك.)"] },
      { question: "پيغمبريمزك (صلى الله عليه و سلم) افضل الأنبيا اولديغنه دليل نه در؟ ايضاح ايديكز؟", answer: ["كُنْتُمْ خَيْرَ اُمَّةٍ اُخْرِجَتْ لِلنَّاسِ آيت كريمه سيدر. شويله كه: أمتك خيرلى اولمسى ديننده كى كمالى اعتباريله در. ديننده كى كمالى ايسه نبيسنك كمالنه تابعدر."] },
      { question: "پيغمبرلردن بعضيسنك كناه ايشله ديكنى اشعار ايدن نقللرك حكمى نه در؟", answer: ["بِطَرِيقِ الآحَادْ مَنْقُولْ ايسه مردوددر. بِطَرِيقِ التَّوَاتُرْ منقول ايسه تأويل اولونور. تأويل ممكن دكلسه أَوْلَیيِی تَرْك معناسنه ويا بِعْثَتْدَنْ اَوَّلْ اولديغنه حمل اولونور."] },
      { question: "جناب حق انبيايى نيچون معجزه ايله تأييد ايتمشدر؟", answer: ["معجزه اولمسه ايدى انبيانك قَوْلنى قبول ايتمك واجب اولمازدى . رسالت دعوی سنده صادق اولان، كاذب اولاندن آيرييلمازدى."] },
      { question: "ملائكه كرام حقنده معلومات ويريكز؟", answer: ["١- ملائكهء كرام الله تعالى نك امرى ايله عامل قوللريدر. (نته كيم جناب حق شويله بويورمشدر: اَ يَسْبِقُونَهُ باِلْقَوْلِ وَهُمْ بأِمْرِهِ يَعْمَلُونَ) ","٢- ارككلك و ديشيلكله وصفلانمازلر","٣- بعضلرينك ظن ايتديكى كبى اللهك قيزلرى دكلدرلر."] },
      { question: "معراج قاچ صفحه در؟ بو صفحه لر نه ايله ثابتدر؟ منكرلرنك حكمى نه در؟", answer: ["اوچ صفحه در.۱- مسجد حرامدن مسجد اقصیيه قدر اولان صفحه. (بوكا إسرا دينير.) آيتله ثابتدر. منكرى كافر اولور. ","۲- مسجد اقصی دن سمواته قدر اولان صفحه. خبر مشهور ايله ثابتدر. منكرى ضَالْ اولور. ","۳- سمواتدن إلى ما شاء الله اولان صفحه. خبر واحد ايله ثابتدر. منكرى آثِمْ  اولور."] },
      { question: "أنبيادن صوكره بشرك اك فضيلتليلرينى  خلافت مدتلريله برابر يازيكز؟", answer: ["أك فضيلتليسى؛ حضرت ابو بكر الصديق -۲-  صوكره حضرت عمر الفاروق -۱٠-  صوكره حضرت عثمان ذى النورين -۱۲-   صوكره  حضرت على كرم الله وجهه -٦-          جمعا -۳٠- سنه در."] },
      { question: "امامك وظيفه لرندن بش دانه سنى يازيكز؟ ", answer: ["۱- تَنْفِيذُ اَحْكَامْ ( حكملرى اجرا ايتمك ) ","۲ - اِقَامَهءِ حُدُودْ ( حدلرى تطبيق ايتمك )","۳  - سَدُّ ثُغُورْ ( حدودلرى محافظه ايتمك ) ","٤- تَجْهِيزُ جُيُوشْ ( اوردويى تجهيز ايتمك","٥-اَخْذِ صَدَقاَتْ ( ويركيلرى طوبلامق ) ","٦-قَهْرِ مُتَغَلِّبَه وَمُتَلَصِّصَه وَقُطَّاعِ طَرِيقْ (عاصيلرى، خرسزلرى ويول كسيجيلرى جزالانديرمق)","٧- إِقَامَهءِ جُمْعَه و أَعْيَادْ (جمعه و بايرامنمازلرينى قيلديرمق )","٨- قوللر آراسنده واقع اولان منازعه لرى كسمك","٩- حقوق اوزرينه قائم اولان شهادتلرى قبول ايتمك","۱٠- وليسى اولميان اركك و قيز جوجوقلرى اولنديرمك","۱- غنيمتلرى تقسيم ايتمك"] },
      { question: "اهل سنتك اقدم اولان علامتلرندن بش دانه سنى يازيكز؟", answer: ["١- تَفْضِيلُ الشَّيْخَيْن (حضرت أبو بكر و عمرك تفضيلى)","٢- حُبُّ الْخَتَنَيْن (حضرت عثمان و على يه محبت بسله مك) ","۳- تَعْظِيمُ الْقِبْلَتَيْن (كعبه و مسجد اقصا يى تعظيم ايتمك) ","٤- اَلْمَسْحُ عَلَى الْخُفَّيْن (مستلر اوزرينه مسح ايتمك)","٥- اَلصَّلَوةُ عَلَى الْجَنَازَتَيْن (بر و فاجر هركسك اوزرينه جنازه نمازى قيلمق) ","٦-اَلصَّلَوةُ خَلْفَ الْإِمَامَيْن (بر و فاجر ايكى امامك آرقاسنده ده نماز قيلمق)","٧- اَلْقَوْلُ بِالْمَعْصُومَيْن (بيغمبرلر و ملكلرك معصوم اولديغنه حكم ايتمك)","٨- اَلْإِيمَانُ بِالْجَارِحَتَيْن (قلب و لسان ايله ايمان ايتمك) ","٩- اَلْإِيَمانُ بِالْحَيَاتَيْن (قبر و آخرت حياتنه ايمان ايتمك)","۱٠- اِثْباَتُ الشَّفَاعَتَيْن (انبياء و صالحلرك شفاعتنى إثبات ايتمك)"] },
      { question: "¬عشره مبشره يى تعداد ايديكز؟", answer: ["۱- حضرت ابو بكر 		٦- حضرت طلحة","۲- حضرت عمر 		٧- حضرت عبد الرحمن بن عوف","۳- حضرت عثمان 		٨- حضرت سعد بن ابى وقاص","٤- حضرت على 		٩- حضرت سعيد بن زيد","٥- حضرت زبير 		۱٠- حضرت ابو عبيدة بن جراح (رضوان الله تعالى عليهم اجمعين)."] },
      { question: "اصحاب كرامه ديل اوزاتمانك ممنوع اولديغنه دائر ايكى حديث شريف يازيكز؟", answer: ["١- لَا تَسُبُّوا اَصْحَابِى فَلَوْ اَنَّ اَحَدَكُمْ اَنْفَقَ مِثْلَ اُحُدٍ ذَهَبًا مَا بَلَغَ مُدَّ اَحَدِهِمْ وَ لَانَصِيفَهُ.","٢- اَللهَ اَللهَ فِى اَصْحَابِى، لَا تَتَّخِذُوهُمْ غَرَضًا مِنْ بَعْدِى، فَمَنْ اَحَبَّهُمْ فَبِحُبِّى اَحَبَّهُمْ وَ مَنْ اَبْغَضَهُمْ فَبِبُغْضِى اَبْغَضَهُمْ، وَ مَنْ آذَاهُمْ فَقَدْ آذَانِى ، وَ مَنْ آذَانِى فَقَدْ آذَى اللهَ  تَعَالَى وَ مَنْ آذَى اللهَ فَيُوشَكُ اَنْ يَأْخُذَهُ."] },
      { question: "فاجرك آرقه سنده نماز و فاجر اوزرينه جنازه نمازى جائز ميدر؟ دليللريله برابر ايضاح ايديكز؟ ", answer: ["فاجرك آرقه سنده نماز جائزدر. چونكه افنديمز: صَلُّوا خَلْفَ كُلِّ بَرٍّ وَ فَاجِرٍ. بيورمشدر، فاجر اوزرينه جنازه نمازى جائزدر. چونكه بو خصوصده اجماع واردر. و پيغمبريمز شويله بيورمشلردر:لَا تَدَعُوا الصَّلَاةَ عَلَى مَنْ مَاتَ مِنْ اَهْلِ الْقِبْلَةِ."] },
      { question: "ولى نيچون نبى درجه سنه اولاشاماز؟", answer: ["چونكه: ١- نبيلر معصومدر، ","٢- خاتمه قورقوسندن اميندر، ","٣- وحی ايله و مَلكى كورمكله اكرام اولونمشلردر،","٤- اوليانك كمالاتيله وصفلاندقدن صوكره احكامى تبليغ و انسانلرى ارشاد ايله مأموردرلر. (ولى ايسه بويله دكلدر)"] },
      { question: "كفرى ايجاب ايدن سببلردن  ٥   دانه سنى يازيكز؟ ", answer: ["۱- نصلرى رد ايتمك،		        ٥- شريعتله آلاى ايتمك،","۲- نصلرك ظاهرندن باطنيه نك ادعا ايتديكى معنالره دونمك،","۳- معصيتى حلال كورمك،            ٦- الله تعالى نك رحمتندن اميد كسمك،","- معصيتى خفيفه آلمق،		٧- الله تعالى نك عذابندن امين اولمق،","٨ - كاهنك غيبدن خبر ويرديكنى ادعا ايتديكى شيلرى تصديق ايتم"] },
      { question: "قيامت علامتلرندن   ٥  دانه سنى يازيكز؟", answer: ["١- دجالك چيقمهسى،                ٢- دابة الأرضك جيقمه سى،","يأجوج و مأجوجك چيقمهسى،    ٤- عيسى عليه السلامك سمادن نزولى، ","٥- كونشك باتيدن طوغمهسى."] }
    ],
    
    'Şemsiyye': [
      { question: "علم منطغك تعريفنى، موضوعنى، غايه سنى، اوقوديغيمز كتابك إسمنى و مصنفنى يازيكز \u061F  ", answer: ["تعريفى: هُوَ آلَةٌ قَانُونِيَّةٌ تَعْصِمُ مُرَاعَاتُهَا الذِّهْنَ عَنِ الْخَطَأِ فِى الْفِكْرِ","موضوعى: معلومات تصوريه و معلومات تصديقيه در.","غايه سى: عِصْمَةُ الذِّهْنِ عَنِ الْخَطَأِ فِى الْفِكْرِ","كتابك إسمى: الرسالة الشمسيةُ فى القواعدِ المنطقيةِ","كتابك مصنفى: على ابن عمر الكاتبى در"]}
      ,{ question: "علم قاج قسمدر؟ تعريفلرى نه لردر؟ ", answer: ["علم ايكى قسمدر:  تَصَوُّرْ : حُصُولُ صُورَةِ الشَّيْئِ فِى الْعَقْلِ","تَصْدِيقْ : تَصَوُّرٌ مَعَهُ حُكْمٌ"] }
    ,{ question: "لفظك معنايه بالوضع دلالتنك قسملرينى ايضاح ايديكز.", answer: ["بالمطابقه: لفظك وضع اولونديغى معنانك تمامى اوزرينه دلالت ايتمه سيدر.","بالتضمن: لفظك وضع اولونديغى معنانك بر جزئى اوزرينه دلالت ايتمه سيدر.","بالالتزام: لفظك وضع اولونديغى معنانك لازمى اوزرينه دلالت ايتمه سيدر."] }
    ,{ question: "جزئى حقيقى و كلى نك تعريفلرينى يازوب ، برر مثال ويريكز. ", answer: ["جزئى حقيقى: مَا يَمْنَعُ نَفْسُ تَصَوُّرِ مَفْهُومِهِ عَنْ وُقُوعِ الشِّرْكَةِ فِيهِ ،  مثال: زيد كبى","كلى : مَالَا يَمْنَعُ نَفْسُ تَصَوُّرِ مَفْهُومِهِ عَنْ وُقُوعِ الشِّرْكَةِ فِيهِ ،  مثال: انسان كبى"] }
    , { question: "نوع حقيقى يى تعريف ايدوب قسملرينى برر مثال ايله يازيكز.", answer: ["نوع حقيقى :كُلِّىٌّ مَقُولٌ عَلَى وَاحِدٍ فَقَطْ اَوْ عَلَى كَثِيرِينَ مُتَّفِقِينَ باِلْحَقَائِقِ فِى جَوَابِ مَا هُوَ. ","مُتَعَدِّدُ الْأَشْخَاصْ اولور: انسان كبى ؛ غير متعدد الأشخاص اولور : شمس كبى  "] }
    ,  { question: "جنسى تعريف ايديب قسملرينى برر مثال ايله يازيكز.", answer: ["جنسى : كُلِّىٌّ مَقُولٌ عَلَى كَثِيرِينَ مُخْتَلِفِينَ بِالْحَقَائِقِ فِى جَوَابِ مَا هُوَ. ","جنس قريب : انسانه نسبتله حيوان كبى  ؛ جنس بعيد : انسانه نسبتله جسم كبى."] }
    ,{ question: "فصلى تعريف ايدوب قسملرينى برر مثال ايله يازيكز.", answer: ["كُلِّىٌّ يُحْمَلُ عَلَى الشَّئْ ِفِى جَوَابِ اَىُّ شَئْ ٍهُوَ فِى جَوْهَرِهِ. ","فصل قريب : ناطق كبى    ؛   فصل بعيد : انسانه نسبتله حَسَّاسْ كبى"] }
    ,{ question: "نسب اربعه يى تعداد ايدوب برر مثال ويريكز.", answer: ["مُسَاوَاتْ - (انسان و ناطق كبى) ","عموم خصوص مطلق -  (انسان و حيوان كبى)","عموم خصوص من وجه - (حيوان و ابيض كبى)","مُبَايَنَتْ - (انسان وفرس كبى)"] }
    ,{ question: "معرفى (قول شارحى) تعريف ايدوب، قسملرينى مثاللريله برابر يازيكز. ", answer: ["وَهُوَ الَّذِى يَسْتَلْزِمُ تَصَوُّرُهُ تَصَوُّرَ ذَلِكَ الشَّئْ ِاَوِ امْتِيَازَهُ عَنْ كُلِّ مَا عَدَاهُ","حد تام  / اَلْاِنْسَانُ حَيْوَانٌ نَاطِقٌ كبى","حد ناقص / اَلْاِنْسَانُ نَاطِقٌ   -  اَلْاِنْسَانُ جِسْمٌ نَاطِقٌ كبى","رسم تام  /  اَلْاِنْسَانُ حَيْوَانٌ ضَاحِكٌ كبى","رسم ناقص  /  اَلْاِنْسَانُ ضَاحِكٌ   -   اَلْاِنْسَانُ جِسْمٌ ضَاحِكٌ  كبى"] }
    , { question: "قضيه يى تعريف ايدوب أقسام أوليه سنى يازيكز.", answer: ["قضيه : قَوْلٌ يَصِحُّ اَنْ يُقَالَ لِقَائِلِهِ اِنَّهُ صَادِقٌ فِيهِ اَوْ كَاذِبٌ فِيهِ","قضيه  حمليه اولور شرطيه اولور . شرطيه ، متصله اولور  منفصله اولور."] }
    , { question: "موضوعى إعتباريله قضيهء حمليه قاج قسمدر؟ و نه لردر؟ إيضاح ايديكز.", answer: ["•	موضوعى شَخْصِ مُعَيَّنْ اولورسه مخصوصه و شخصيه ده نير.","•	موضوعى كُلِّى اولوب فردلرك كَمِيَّتِى بيان اولونرسه مَحْصُورَه و مُسَوَّرَه دينير.","•	بيان اولونمايوب كُلِّيَّه وَ جُزْئِيَّه اولمايه صالح اولمازسه طَبِيعِيَّه دينير.","•	بيان اولونمايوب كُلِّيَّه وَ جُزْئِيَّه اولمايه صالح اولورسه مُهْمَلَه دينير."] }
    ,{ question: " شكل نه يه  ده نير اشكال اربعه يى بيان ايديكز \u061F", answer: ["حَدِّ اَوْسَطِكْ حَدّ اصغر و حَدّ اكبر ياننده  وَضِعْ كيفيتندن حاصل اولان هيئته شكل ده نير.","شكل اول   : حد اوسط صغرى ده محمول ، كبرى ده موضوع اولورسه","شكل ثانى   : حد اوسط هر ايكيسنده ده محمول اولورسه","شكل ثالث  : حد اوسط هر ايكيسنده ده موضوع اولورسه","شكل رابع   : حد اوسط صغرى ده موضوع ، كبرى ده محمول اولورسه"] }
    ,{ question: "يقينيات و غير يقينياتى تعداد ايديكز.", answer: ["يقينيات : اَوَّلِيَاتْ ، مُشَاهَدَاتْ ، مُجَرَّبَاتْ ، حَدْسِيَّاتْ ، مُتَوَاتِرَاتْ ، قَضَايَا قِيَاسَاتُهَا مَعَهَا","غير يقينيات 	: مَشْهُورَاتْ ، مُسَلَّمَاتْ ، مَقْبُولَاتْ ، مَظْنُونَاتْ ، مُخَيَّلاَتْ ، وَهْمِيَّاتْ "] }
    ,{ question: "برهان نه يه ده نير؟ قسملرينى إيضاح ايديكز.", answer: ["يقينياتدن ميدانه كلن قياسه دينير","برهان لمى   : مُؤَثِّرْدَنْ اثره استدلالدر","برهان انى   : اثردن مُؤَثِّرَه استدلالدر."] }
    ]
  };
  
  module.exports = qaPairs;
 