const words = [
    {
      author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
      quote: "Üniversite bitiren birisi olarak söylüyorum, Tekâmül demek en az iki üniversite bitirmek demektir. O güzel vazifeyi, mesuliyeti alıp hakkını vermekte Cenabı Hakk hepimizi muvaffak kılsın.",
    },
    {
      author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
      quote: "Tekâmül eğitimi karakter eğitimidir. Türkiye’de en üst seviyede karakter eğitimi tekâmül eğitimidir.",
    },
    {
      author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
      quote: "Tekâmül’ü bitirenlere Hz. Üstazımızın şefaati vaciptir.",
    },
    {
      author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
      quote: "Sizler de inşallah Kur'an-ı Kerim'i, onun ilmini en iyi şekilde öğrenip Tekamüle gelecek; sonra da dünyanın her tarafına bu ilmi öğretebilmek için gideceksiniz",
    },
    {
        author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
        quote: "Biz tekâmül talebelerine mezun oldukları zaman şahadetname veriyoruz, o şahadetnamede hangi mühür vardır. Ne imzalar vardır, Rasülullah efendimizin mühr-ü şerifleri vardır.",
      },
      {
        author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
        quote: " Zihnin inkişafı için maneviyat, rabıta ve zikri kalbi şarttır. Bu Hz. Üstazımızın sözüdür. Biz bunu, tekâmül talebelerini imtihan ettiğimizde, görüyoruz. Son senelerde, notlar doksan beşin üzerinde. Bu da talebelerimizin, maneviyata yapıştıklarını gösteriyor.",
      },
      {
        author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
        quote: "Tekâmülden mezun olmak demek, bu diplomayı almak demek; Piranımız’ın Hz. Üstazımız’ın şefaatinin vacip olması demektir. Bunu çok iyi anlamak lazım. ",
      },
      {
        author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
        quote: "Verilen o icazet, o şahadetname, o diploma güneşin doğup battığı her yerde geçerlidir. Bu altın bir anahtardır. İşte o icazetname izleri takip etmenizi sağlıyor.",
      },
      {
        author: "Hazreti Üstazımız",
        quote: "Evlatlarım geldiğiniz yerlerde de bu kitapları okutturabilirdik amma bizim amacımız İstanbul’a gelip İstanbul beyfendisi , dava adamı olabilmek için buraya çağırıyoruz.",
      },
      {
        author: "Ahmet Arif Denizolgun Bey Ağabeyimiz",
        quote: "Eğer keyfiyetimiz iyi olursa dağ taş Süleymanlı olur.",
      },

      {
        author: "Kemal Bey Ağabeyimiz",
        quote: "Hazreti üstadımıza en yakın olan tekamül talebeleridir .",
      },

      {
        author: "Alihan Bey Ağabeyimiz",
        quote: "bu alemin , dünyanın bekası , geleceği , yerin altındakilerin de üstündekilerin de selameti bu notlara bağlı .",
      },
  ];

  module.exports = words;