import React, { useState, useEffect } from 'react';
import { Text,Stack,

  } from '@chakra-ui/react';

const CountdownTimer = () => {
  const targetDate = new Date('2024-06-01T10:00:00');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
    <Stack align={"center"}  spacing='3'>
      <Text align="center" fontSize={ {base: 'md', md: '2xl'} }>İmtihana kalan süre</Text>
      <Text align="center" fontSize={ {base: 'lg', md: '3xl'} }>
        {timeLeft.days} Gün {timeLeft.hours} Saat {timeLeft.minutes} Dakika {timeLeft.seconds} Saniye
      </Text>
    </Stack>
    </>
  );
};

export default CountdownTimer;
